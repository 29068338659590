import logo from '../logo-maxime-2.svg';
import { NavLink, useLocation } from "react-router-dom";

function Logo() {
    const location = useLocation();
    let logoHtml = '';
    if(location.pathname === "/") {
        logoHtml = <h1><NavLink to="/"><img className="logo" src={logo} alt="Site web de Maxime Louf" /></NavLink></h1>
    } else {
        logoHtml = <NavLink to="/"><img className="logo" src={logo} alt="Accéder à l'accueil" /></NavLink>
    }
    return (
        <div>
            {logoHtml}
        </div>
    );
}

export default Logo;