import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

function App() {
    return (
        <Router>
            <Routes>
              <Route path="/" element={<Layout />} title="Maxime Louf - Accueil">
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} title="Maxime Louf - A propos" />
                <Route path="/portfolio" element={<Portfolio />} title="Maxime Louf - Portfolio" />
                <Route path="/contact" element={<Contact />} title="Maxime Louf - Contact" />
                <Route path="*" element={<NoPage />} title="Maxime Louf - Accueil" />
              </Route>
            </Routes>
        </Router>
    );
}
  
export default App;