import { Link } from "react-router-dom";

const Home = () => {
    return (
      <>
        <div className="m-home">
          <div className="l-container">
            <p className="m-home__title">Hello, je suis Maxime</p>
            <p className="m-home__introduction">Ce site est un petit portfolio professionnel. <br/> N'h&eacute;sitez pas &agrave; <Link to="/contact">m'envoyer un message</Link> pour en savoir plus.</p>
          </div>
        </div>
      </>
    );
  }
  
  export default Home;