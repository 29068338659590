import '../App.scss';
import { Outlet, NavLink, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import Logo from "../modules/Logo";

function Layout() {
    const location = useLocation();
    let introText = '';
    switch (location.pathname) {
        case '/portfolio':
          introText = '<h1 className="welcome u-text-center">Portfolio</h1>';
          break;
        case '/about':
            introText = '<h1 className="welcome u-text-center">A propos</h1>';
            break;
        case '/contact':
            introText = '<h1 className="welcome u-text-center">Contact</h1>';
            break;
        default:
            introText = '<p className="welcome u-text-center">Développeur Front-end</p>';
    }
  return (
    <>
      <div id="document" className="o-document">
        <header className="l-grid-header">
            <Logo />
            {parse(introText)}
            <nav className="c-nav" role="navigation">
                <ul className="c-nav__list">
                    <li className="c-nav__item"><NavLink className={({ isActive }) => (isActive ? 'c-nav__link c-nav__link--active ' : 'c-nav__link')} to="/portfolio">Portfolio</NavLink></li>
                    <li className="c-nav__item"><NavLink className={({ isActive }) => (isActive ? 'c-nav__link c-nav__link--active ' : 'c-nav__link')} to="/about">A propos</NavLink></li>
                    <li className="c-nav__item"><NavLink className={({ isActive }) => (isActive ? 'c-nav__link c-nav__link--active ' : 'c-nav__link')} to="/contact">Contact</NavLink></li>
                </ul>
            </nav>
        </header>
        <Outlet />
        <footer role="contentinfo">
            <p>Réalisé avec <a href="https://fr.reactjs.org/" target="_blank" rel="noopener noreferrer">React</a></p>
        </footer>
      </div>
    </>
  );
}

export default Layout;