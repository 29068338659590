function Card(props) {
    const projet = props.projet;
    const url = props.url;
    const entreprise = props.entreprise;
    const cms = props.cms;
    const year = props.year;
    return (
        <>
            <div className="c-card">
                <div className="c-card__content">
                    <h2 className="c-card__title">
                        {projet}
                    </h2>
                    <p className="c-card__label">Réalisé avec :</p>
                    <p className="c-card__info">{entreprise}</p>
                    <p className="c-card__info">sur {cms}</p>
                    <p className="c-card__info">en {year}</p>
                </div>
                <a className="c-card__link o-button u-cover" href={url} target="_blank" rel="noopener noreferrer">
                    Voir le site
                </a>
            </div>
        </>
    );
}

export default Card;